
import eventTrackingMixins from '~/mixins/eventTrackingMixins.js';
import lgmMixin from '~/mixins/lgmMixin';
export default {
  mixins: [eventTrackingMixins, lgmMixin],

  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    mobileOnly: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleClick(event) {
      event.preventDefault();

      const eventTrackingParams = this.handleEventTracking(event)
      const dataFromClickedElement = this.extractDataFromClickedElement(event.target)

      this.$goToURL(dataFromClickedElement.href, false, eventTrackingParams, {
        getLGMConversionId: this.getLGMConversionId,
        injectLGMConversionId: this.injectLGMConversionId,
        injectLGMConversionIdToEComSite: this.injectLGMConversionIdToEComSite
      });

    },
    
    handleEventTracking(event){
      event.preventDefault()
      const dataFromClickedElement = this.extractDataFromClickedElement(event.target)
      const href = dataFromClickedElement.href

      if (this.$isWhatsappLink(href)) {
        const eventTrackingParams = this.initEventTrackingParams()
        eventTrackingParams.a = 'open_whatsapp'
        eventTrackingParams.l =  ``
        eventTrackingParams.c = `broker_nav`
        this.sendEventTracking(eventTrackingParams)
        return eventTrackingParams
      }    

      return null
    },
  }
};
