export default ({ app }, inject) => {
  // Inject $myFunction in Vue, context, and store.
  inject('myFunction', (message) => console.log(message));
  
  // define an object to collect the following functions.
  const trackingService = {
  
    async get10lifeCid() {
      // const url = 'https://jsonplaceholder.typicode.com/todos/1';
      // const response = await this.$axios.get(url);
      // simulate a async response that delay 3 seconds
      const response = await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ data: { cid: '123' } });
        }
        , 3000);
      }
      );
  
      return response.data;
    },

    checkWhatsappLGMTrackingConditions(url) {
      return (this.$hasAdsInCookies() || this.$hasLgmadsInCookies());
    },

    goToURL(url, openInNewWindow = false, eventTrackingParams, lgmFunctions) {
      
      event.preventDefault();
      const getLGMConversionId = lgmFunctions?.getLGMConversionId
      const injectLGMConversionId = lgmFunctions?.injectLGMConversionId
      const injectLGMConversionIdToEComSite = lgmFunctions?.injectLGMConversionIdToEComSite
      const linkType = this.$getLinkType(url);

      if (url.includes('javascript:void(0)') || url.includes('javascript:')) {
        return;
      }
      if (linkType === 'whatsapp') {

        if (this.$checkWhatsappLGMTrackingConditions(url)) {
          const lgmParams = {
            "type": "wtsapp",
            "epg": window.location.href,
            "dpg": url,
            "lgmMeta": eventTrackingParams.c ? eventTrackingParams.c : undefined,
            "xr_id": eventTrackingParams.xr_id ? eventTrackingParams.xr_id : undefined,
            "ir": eventTrackingParams.cd.insurer_name ? eventTrackingParams.cd.insurer_name : undefined,
          }

          if (this.$device.isMobile) {
            getLGMConversionId(lgmParams).then((cid) => {
              const urlWithCid = this.$adjustWhatsappLink(url, cid);
              window.open(urlWithCid, '_self');
            });
          } else {
            const windowReference = window.open();
            getLGMConversionId(lgmParams).then((cid) => {
              const urlWithCid = this.$adjustWhatsappLink(url, cid);
              windowReference.location = urlWithCid;
            });
          }

        } else {
          window.open(url, this.$device.isMobile ? '_self' : '_blank');
        }
      } else if (linkType === 'external') {
        const lgmParams = {
          "type": "ext-link",
          "epg": window.location.href,
          "dpg": url,
          "lgmMeta": eventTrackingParams.c ? eventTrackingParams.c : undefined,
          "xr_id": eventTrackingParams.xr_id ? eventTrackingParams.xr_id : undefined,
          "ir": eventTrackingParams.cd.insurer_name ? eventTrackingParams.cd.insurer_name : undefined,
        }

        const windowReference = window.open();
        getLGMConversionId(lgmParams).then((cid) => {
          const urlWithCid = injectLGMConversionId(url, cid);
          windowReference.location = urlWithCid;
        });

      } else if (linkType === 'ecom') {
        const lgmParams = {
          "type": "ecom",
          "epg": window.location.href,
          "dpg": url,
          "lgmMeta": eventTrackingParams.c ? eventTrackingParams.c : undefined,
          "xr_id": eventTrackingParams.xr_id ? eventTrackingParams.xr_id : undefined,
          "ir": eventTrackingParams.cd.insurer_name ? eventTrackingParams.cd.insurer_name : undefined,
        }

        const windowReference = window.open();
        getLGMConversionId(lgmParams).then((cid) => {
          const urlWithCid = injectLGMConversionIdToEComSite(url, cid);
          windowReference.location = urlWithCid;
        });

      } else {
        window.open(url, openInNewWindow ? '_blank' : '_self');
      }
    },

    getLinkType(url) {
      if (this.$isWhatsappLink(url)) {
        return 'whatsapp';
      } else if (this.$isEComLink(url)) {
        return 'ecom';
      } else if (this.$isTransitionLink(url)) {
        return 'external';
      } 
      return 'other';
    },
    
    async fetchWhatsappRefCode(url, eventTrackingParams) {
      try {
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        const delayed = async () => {
          await delay(10);

          const currentUrl = window.location.href;
          try {
            const lgmParams = {
              "type": "wtsapp",
              "epg": currentUrl,
              "dpg": url,
              "lgmMeta": eventTrackingParams.c ? eventTrackingParams.c : undefined,
              "xr_id": eventTrackingParams.xr_id ? eventTrackingParams.xr_id : undefined,
              "ir": eventTrackingParams.cd.insurer_name ? eventTrackingParams.cd.insurer_name : undefined,
            }
            return await this.getLGMTrackingHash(lgmParams);
          } catch (error) {
            console.error('Error:', error);
            this.$sentry.captureException(error);
            return null;
          }

        }
        return await delayed()

      } catch (error) {
        console.error('error: ', error)
        this.$sentry.captureException(error);
      }
      return null
    },
    
    adjustWhatsappLink(link, refText) {
      if (this.$isWhatsappLink(link)) {
        return this.$prependAdRefToWhatsappText(link, refText);
      }
      return link;
    },
    prependAdRefToWhatsappText(whatsappLink, refString) {
      if(this.$hasAdsInCookies() || this.$hasLgmadsInCookies()) {
        if(!refString) refString = '10Life' 
        const link = whatsappLink?.replace('text=', (`text=<10Life Ref:${refString}> `))
        return link
      }
      return whatsappLink
    },
  
    async sendEventTracking(params, immediate) {
      const timeout = immediate ? 0 : 1000;
      if (params.a !== '') {
        return await new Promise((resolve) => {
          const interval = setInterval(() => {
            if (window.eventTracking) {
              clearInterval(interval);
              console.debug('sendEventTracking....: ', params);
              window.eventTracking(params);
              resolve();
            }
          }, timeout);
        });
      }
    },
    
    hasAdsInCookies() {
      if(this.$cookies.get('_10life_ads')) {
        return true
      }
      return false
    },
    hasLgmadsInCookies() {
      if(this.$cookies.get('_10life_lgmads')) {
        return true
      }
      return false
    },
    isWhatsappLink(link) {
      const boo = link && (link.includes('api.whatsapp.com') || link.includes('https://wa.me'))
      return boo
    },
    isEComLink(link) {
      return link.includes(process.env.SALES_EXTERNAL_URL)
    },
    isTransitionLink(link) {
      return link?.includes('transition-checkout');
    },
  
  };

  // loop through the trackingService and inject the fucntions to Vue, context, and store.
  for (const [key, value] of Object.entries(trackingService)) {
    inject(key, value);
  }

};