// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/nav-bar/nav-content/background-visual.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes circular-animation-15fb327e{to{stroke-dashoffset:0}}.card-visual[data-v-15fb327e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;min-height:160px;min-width:350px}.card-visual .content[data-v-15fb327e]{align-items:center;display:flex;justify-content:space-evenly;min-height:160px}.card-visual .person-visual img[data-v-15fb327e]{width:100px}.card-visual .sub-text[data-v-15fb327e]{color:#000;max-width:220px}.card-visual .sub-text .title[data-v-15fb327e]{font-size:28.8px;font-weight:700;margin-bottom:8px}[lang=en-US] .card-visual .sub-text .title[data-v-15fb327e]{font-size:24px}.card-visual .sub-text .description[data-v-15fb327e]{font-size:14.4px}[lang=en-US] .card-visual .sub-text .description[data-v-15fb327e]{font-size:12px}.mobile-style[data-v-15fb327e]{display:flex;padding-bottom:20px}.mobile-style .card-visual[data-v-15fb327e]{min-width:0}.mobile-style .card-visual .content[data-v-15fb327e]{gap:8px;padding:0 8px}.mobile-style .sub-text .title[data-v-15fb327e]{font-size:24px}[lang=en-US] .mobile-style .sub-text .title[data-v-15fb327e]{font-size:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
