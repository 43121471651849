import middlePlatformTrackingMixin from "./middlePlatformTrackingMixin";

export default {
  mixins: [middlePlatformTrackingMixin],
  data() {
    return {
      middlePlatformUrl: process.env.MIDDLE_PLATFORM_URL,
    };
  },
  methods: {
    getUid() {
      const gaID = this.$cookies.get('_ga');
      const match = gaID.match(/.*\.[\d]\.(\d+\.\d+)$/);
      return match[1];
    },
    async fetchWhatsappRefCode(url, eventTrackingParams) {
      try {
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        const delayed = async () => {
          await delay(10);

          const currentUrl = window.location.href;
          try {
            const lgmParams = {
              "epg": currentUrl,
              "dpg": url,
              "lgmMeta": eventTrackingParams.c ? eventTrackingParams.c : undefined,
              "xr_id": eventTrackingParams.xr_id ? eventTrackingParams.xr_id : undefined,
              "ir": eventTrackingParams.cd.insurer_name ? eventTrackingParams.cd.insurer_name : undefined,
            }
            return await this.getLGMTrackingHash(lgmParams);
          } catch (error) {
            console.error('Error:', error);
            this.$sentry.captureException(error);
            return null;
          }

        }
        return await delayed()

      } catch (error) {
        console.error('error: ', error)
        this.$sentry.captureException(error);
      }
      return null
    },
    hasAdsInCookies() {
      if(this.$cookies.get('_10life_ads')) {
        return true
      }
      return false
    },
    hasLgmadsInCookies() {
      if(this.$cookies.get('_10life_lgmads')) {
        return true
      }
      return false
    },
    isWhatsappLink(link) {
      const boo = link && (link.includes('api.whatsapp.com') || link.includes('https://wa.me'))
      return boo
    },
    adjustWhatsappLink(link, refText) {
      if (this.isWhatsappLink(link)) {
        return this.prependAdRefToWhatsappText(link, refText);
      }
      return link;
    },
    prependAdRefToWhatsappText(whatsappLink, refString) {
      if(this.hasAdsInCookies() || this.hasLgmadsInCookies()) {
        if(!refString) refString = '10Life'
        const link = whatsappLink?.replace('text=', (`text=<10Life Ref:${refString}> `))
        return link
      }
      return whatsappLink
    },
  }
}
